<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailInfo" title="설비 기본정보">
          <template slot="card-detail">
            <div v-if="editable && !popupParam.multiple && popupParam.noPlan === 'N'" class="col-3">
              <c-label-text title="정비부서" :value="result.deptNm"></c-label-text>
            </div>
            <div v-if="editable && !popupParam.multiple" class="col-3">
              <c-label-text title="설비유형" :value="result.equipmentType"></c-label-text>
            </div>
            <div v-if="editable && !popupParam.multiple" class="col-3">
              <c-label-text title="설비코드" :value="result.equipmentCd"></c-label-text>
            </div>
            <div v-if="editable && !popupParam.multiple" class="col-3">
              <c-label-text title="설비명" :value="result.equipmentNm"></c-label-text>
            </div>
            <div v-if="editable && !popupParam.multiple && popupParam.noPlan === 'N'" class="col-3">
              <c-label-text title="정비유형" :value="result.safFacilityNm"></c-label-text>
            </div>
            <div v-if="editable && !popupParam.multiple && popupParam.noPlan === 'N'" class="col-3">
              <c-label-text title="정비부서 입회자" :value="result.chkUserNm"></c-label-text>
            </div>
            <div v-if="editable && !popupParam.multiple && popupParam.noPlan === 'N'" class="col-3">
              <c-label-text title="정비예정일" :value="result.chkSchYmd"></c-label-text>
            </div>
            <div v-if="editable && !popupParam.multiple && popupParam.noPlan === 'N'" class="col-3">
              <c-label-text title="정비예정자" :value="result.chkUserNm"></c-label-text>
            </div>
            <div v-if="editable && !popupParam.multiple" class="col-3">
              <c-label-text title="이전정비일" :value="result.a"></c-label-text>
            </div>
            <div v-if="editable && !popupParam.multiple" class="col-3">
              <c-label-text title="정비주기" :value="result.cycle"></c-label-text>
            </div>
            <div v-if="editable && !popupParam.multiple" class="col-3">
              <c-label-text title="차기법정예정월" :value="result.b"></c-label-text>
            </div>
            <div v-if="editable && !popupParam.multiple" class="col-3">
              <c-label-text title="관련법규" :value="result.c"></c-label-text>
            </div>
            <div class="col-12">
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailForm" title="설비 정비결과" topClass="topcolor-orange" bgClass="">
          <template slot="card-detail">
            <div class="col-3">
              <c-text
                :editable="editable"
                label="실제정비자"
                :disabled="true"
                name="chkRsltSmry"
                v-model="result.chkUserNm">
              </c-text>
            </div>
            <div class="col-3">
              <c-datepicker
                :editable="editable"
                label="정비완료일"
                :disabled="true"
                name="chkSchCompleYmd"
                v-model="result.chkSchCompleYmd">
              </c-datepicker>
            </div>
            <div class="col-3">
              <c-select
                :editable="editable"
                :comboItems="chkRsltYnItems"
                type="edit"
                itemText="codeName"
                itemValue="code"
                :disabled="true"
                name="chkRsltYn"
                label="정비완료여부"
                v-model="result.chkRsltYn"
              ></c-select>
            </div>
            <div class="col-3">
              <c-select
                :editable="editable"
                :comboItems="chkResultItems"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="chkResult"
                :disabled="true"
                label="정비후 점검결과"
                v-model="result.chkResult"
              ></c-select>
            </div>
            <div class="col-3">
              <c-datepicker
                :editable="editable"
                label="정비시간"
                :disabled="true"
                name="chkMaintenanceYmd"
                v-model="result.chkMaintenanceYmd">
              </c-datepicker>
            </div>
            <div class="col-3">
              <c-datepicker
                :editable="editable"
                label="운전정지시간"
                :disabled="true"
                name="chkStopYmd"
                v-model="result.chkStopYmd">
              </c-datepicker>
            </div>
            <div class="col-6">
              <c-text
                :editable="editable"
                label="정비자 자격사항"
                :disabled="true"
                name="chkUserQualify"
                v-model="result.chkUserQualify">
              </c-text>
            </div>
            <div class="col-6">
              <c-textarea
                :editable="editable"
                label="정비원인"
                :disabled="true"
                name="chkRsltCause"
                v-model="result.chkRsltCause">
              </c-textarea>
            </div>
            <div class="col-6">
              <c-textarea
                :editable="editable"
                label="정비내용"
                :disabled="true"
                name="chkRsltContent"
                v-model="result.chkRsltContent">
              </c-textarea>
            </div>
            <div class="col-6">
              <c-textarea
                :editable="editable"
                label="정비결과요약"
                :disabled="true"
                name="chkRsltSmry"
                v-model="result.chkRsltSmry">
              </c-textarea>
            </div>
            <div class="col-6">
              <c-textarea
                :editable="editable"
                label="관리자의 검토의견"
                :disabled="true"
                name="managerOpinion"
                v-model="result.managerOpinion">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'equipment-maintenance-result',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        disabled: false,
        noPlan: 'N',
        multiple: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        plantName: '사업장1',
        deptNm: '안전환경팀',
        safFacilityNm: '예방점검',
        safFacilityCd: '1',
        equipmentType: '집진설비',
        equipmentNm: '집진설비 설비명',
        equipmentCd: 'F3-G938',
        chkSchYmd: '2021-07-24',
        chkUserId: '1',
        chkUserNm: '홍씨',
        remark: '집진설비에 대한 비고',
        chkRsltSmry: '점검결과요약',
        chkRsltContent: '',
        chkRsltCause: '',
        managerOpinion: '검토의견',
        chkUserQualify: '자격',
        cycle: '1개월',
        a: '2021-07-02',
        b: '8월',
        c: '산업안전보건법'
      },
      grid: {
        columns: [
          {
            name: 'deptNm',
            field: 'deptNm',
            label: '정비부서',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentType',
            field: 'equipmentType',
            label: '설비유형',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentCd',
            field: 'equipmentCd',
            label: '설비코드',
            align: 'center',
            sortable: false
          },
          {
            name: 'chngNum',
            field: 'chngNum',
            label: 'MOC 번호',
            align: 'center',
            sortable: false,
          },
          {
            name: 'safFacilityNm',
            field: 'safFacilityNm',
            label: '정비유형',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'chkUserNm',
            field: 'chkUserNm',
            label: '정비예정자',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'chkSchYmd',
            field: 'chkSchYmd',
            label: '정비예정일',
            align: 'center',
            sortable: false,
          },
          {
            name: 'cycle',
            field: 'cycle',
            label: '정비주기',
            align: 'center',
            sortable: true
          },
          {
            name: 'a',
            field: 'a',
            label: '이전정비일',
            align: 'center',
            sortable: true
          },
          {
            name: 'b',
            field: 'b',
            label: '차기법정예정월',
            style: 'width:80px',
            align: 'center',
            sortable: true
          },
          {
            name: 'c',
            field: 'c',
            label: '관련법규',
            align: 'left',
            style: 'width:200px',
            sortable: true
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },

        ],
        data: [],
        height: '300px'
      },
      title: '',
      chkResultItems: [],
      chkRsltYnItems: [],
      disabled: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      this.safFacilityItems = [
        { code: '1', codeName: '예방정비' },
        { code: '2', codeName: '예측정비' },
        { code: '3', codeName: '고장정비' },
      ]
      this.chkResultItems = [
        { code: '1', codeName: '적합' },
        { code: '2', codeName: '부적합' },
      ];
      this.chkRsltYnItems = [
        { code: 'Y', codeName: '완료' },
        { code: 'Y', codeName: '미완료' },
      ]
      // list setting
      this.title = this.popupParam.title;
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          plantName: '사업장1',
          deptNm: '안전환경팀',
          safFacilityNm: '자체(일상)점검',
          safFacilityCd: '1',
          equipmentType: '집진설비',
          equipmentNm: '집진설비 설비명',
          equipmentCd: 'F3-G938',
          chkSchYmd: '2021-07-24',
          chkUserId: '1',
          chngNum: 'MOC01',
          chkUserNm: '홍씨',
          remark: '집진설비에 대한 비고',
          chkRsltSmry: '점검결과요약',
          cycle: '1개월',
          a: '2021-07-12',
          b: '8월',
          c: '산업안전보건법'
        },
        {
          plantName: '사업장1',
          deptNm: '개발팀',
          safFacilityNm: '법정(자체)점검',
          safFacilityCd: '2',
          equipmentType: '소방설비',
          equipmentNm: '소방설비 설비명',
          equipmentCd: 'F3-G940',
          chkSchYmd: '2021-07-24',
          chkUserId: '1',
          chngNum: 'MOC02',
          chkUserNm: '김씨',
          remark: '소방설비에 대한 비고',
          chkRsltSmry: '소방설비에 대한 점검결과요약',
          cycle: '1개월',
          a: '2021-07-12',
          b: '8월',
          c: '산업안전보건법, 보건법'
        },
      ];
    },
    add() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'none'
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data) {
          this.result.plantName = data.plantName;
          this.result.deptNm = data.deptNm;
          this.result.chkUserNm = '';
          this.result.chkUserId = '';
          this.result.safFacilityNm = null;
          this.result.safFacilityCd = null;
          this.result.equipmentType = data.equipType;
          this.result.equipmentCd = data.equipCd;
          this.result.equipmentNm = data.equipNm;
          this.result.chkSchYmd = '';
          this.result.remark = '';
      }
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data = this.$_.reject(this.grid.data, { equipmentCd: item.equipmentCd })
        })
      }
    },
    rowClick(row) {
      this.result = this.$_.clone(row);
    },
    btnRecord() {
      this.popupOptions.target = () =>
      import(`${"@/pages/mdm/fim/equipmentPreview.vue"}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '40%';
      this.popupOptions.param = this.result;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
